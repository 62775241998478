import React from 'react'
import { graphql } from 'gatsby'

import SwitchTheme from 'components/SwitchTheme'
import Redactor from 'components/Redactor'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'

import InstagramPosts from './Posts'
import {
  LinkButtonStyled,
  SectionStyled,
  TextStyled,
  TextWrapper,
  Title,
} from './InstagramStyled'

export default function Instagram({ pageBuilder }) {
  const {
    instagramLink,
    instagramText,
    instagramTitle,
    instagramTheme,
  } = pageBuilder

  const instagramCustomText = instagramLink?.customText
  const instagramFullUri = instagramLink?.entry?.fullUri || instagramLink.url

  return (
    <SwitchTheme name={instagramTheme}>
      <SectionStyled>
        <Grid>
          <GridItem mobile={1} laptop={1 / 2}>
            <TextWrapper>
              <Title>{instagramTitle}</Title>
              <TextStyled>
                <Redactor
                  content={instagramText?.content}
                  parseType="content"
                />
              </TextStyled>
              <LinkButtonStyled
                icon="arrowRight"
                type="heavy"
                uppercase
                to={instagramFullUri === '' ? '/' : instagramFullUri}>
                {instagramCustomText}
              </LinkButtonStyled>
            </TextWrapper>
          </GridItem>
          <GridItem
            mobile={1}
            laptop={1 / 2}
            style={{ position: 'relative', flexWrap: 'no-wrap' }}>
            <InstagramPosts postCount={9} instagramFullUri={instagramFullUri} />
          </GridItem>
        </Grid>
      </SectionStyled>
    </SwitchTheme>
  )
}

export const query = graphql`
  fragment PageBuilderInstagramQuery on Craft_PageBuilderInstagram {
    id
    instagramText {
      content
    }
    instagramTheme
    instagramLink {
      customText
      url
      entry {
        fullUri
      }
    }
    instagramTitle
  }
`
