import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Icon from 'components/Icon'

import {
  ImageContentStyled,
  ImageStyled,
  ImageLinkStyled,
  // ImageLinkOverlayStyled,
  // ImageLinkOverlayIconWrapperStyled,
} from './PostsStyled'

function Posts(props) {
  const {
    data: {
      craft: { entries },
    },
  } = props

  const { postCount, instagramFullUri } = props

  const posts = entries
    .filter((entry) => entry.__typename === 'Craft_Instagram')
    .sort((a, b) => b.postDate - a.postDate) // sort: new to old
    .slice(0, postCount) // take first postCount posts

  return (
    <ImageContentStyled>
      {posts.map((post, i) => {
        const { instagramImageAsset, instagramLink, id } = post
        return (
          <ImageLinkStyled key={id} to={instagramLink || instagramFullUri}>
            {/* {posts.length - 2 === i ? (
              <ImageLinkOverlayStyled>
                <ImageLinkOverlayIconWrapperStyled>
                  Follow us on
                </ImageLinkOverlayIconWrapperStyled>
                <Icon type="instagram" />
              </ImageLinkOverlayStyled>
            ) : null} */}
            <ImageStyled
              fluid={instagramImageAsset?.[0] || null}
              aspectRatio={1 / 1}
            />
          </ImageLinkStyled>
        )
      })}
    </ImageContentStyled>
  )
}

const instaQuery = graphql`
  query {
    craft {
      entries {
        __typename
        ... on Craft_Instagram {
          id
          instagramLink
          instagramImageAsset {
            ...CraftImageFluid
          }
          postDate
        }
      }
    }
  }
`

const InstagramPosts = (props) => (
  <StaticQuery
    query={instaQuery}
    render={(data) => <Posts data={data} {...props} />}
  />
)

export default InstagramPosts
