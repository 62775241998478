import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Button from "components/Button";
import Headline from "components/Headline";

export const TextWrapper = styled("div")({
  maxWidth: 480,
  marginBottom: 24,

  [media("laptop")]: {
    marginBottom: 0,
  },

  [media("desktop")]: {
    paddingTop: 72,
    paddingBottom: 72,
  },
});

export const Title = styled(Headline)({
  fontSize: "1.7rem",
  textTransform: "uppercase",
  fontWeight: 800,
  letterSpacing: "1px",
});

export const TextStyled = styled("div")({
  fontWeight: 400,
  marginBottom: "3rem",
  lineHeight: "20px",
});

export const LinkButtonStyled = styled(Button)({});

export const ContentStyled = styled("div")({
  [media(0, "tablet")]: {
    display: "inline-block",
  },

  padding: "75px 28px 25px 28px",
});

export const SectionStyled = styled("section")(
  {
    padding: "70px 0",
    position: "relative",
    zIndex: 1,
  },
  ({ theme }) => ({
    backgroundColor: theme.backgroundInstagram,
  })
);
