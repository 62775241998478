import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'
import Image from 'components/Image'

import Link from 'components/Link'

export const ImageContentStyled = styled('div')({
  position: 'relative',
  right: 0,
  zIndex: '2',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  display: 'flex',
  [media('laptop')]: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(30%, -50%)',
    width: '120%',
  },
  [media('desktop')]: {
    width: '115%',
  },
})

export const ImageLinkStyled = styled(Link)({
  position: 'relative',
  width: 'calc(50% - 6px)',
  paddingTop: 'calc(50% - 6px)',
  marginBottom: 12,
  height: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '&:nth-of-type(n+7)': {
    display: 'none',
  },
  [media('laptop')]: {
    width: 'calc(33% - 6px)',
    paddingTop: 'calc(33% - 6px)',
    '&:nth-of-type(n+7)': {
      display: 'flex',
    },
  },
})

export const ImageStyled = styled(Image)(
  {
    position: 'absolute !important',
    top: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'background-size 200ms, transform 200ms',
    willChange: 'background-size, transform',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }
  // ({ url }) => ({
  //   // image as background
  //   backgroundImage: `url(${url})`,
  // })
)

export const ImageLinkOverlayStyled = styled('div')({
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 3,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ImageLinkOverlayIconWrapperStyled = styled('span')({
  marginRight: 10,
})
